import { Alert, Button } from "components/core";
import FormField from "components/forms/FormField";
import { DATA_STATUS } from "constants.js";
import { buildFormValues } from "helpers/formsUtilities";
import {
  getFormDataStatus,
  getPenDataFromFormData,
  IForm,
  IFormData,
  IFormField,
  IFormValue,
  IFormValueDataSources,
  IPenFormValid,
  reduceFormDataToObject,
} from "helpers/formUtilities";
import { isNullEmptyOrWhitespace } from "helpers/stringUtilities";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Card, { CardTitle } from "./Card";
import { ButtonClicked } from "./core/Buttons/ButtonClicked";
import FieldsetSkeleton from "./core/Forms/FieldsetSkeleton";
import { usePersistantFormValues } from "context/PersistantFormValuesProvider";
import useNetworkStatus from "hooks/useNetworkStatus";
import { toast } from "react-toastify";

interface DashboardParamsProps {
  form: IForm;
  onSubmit: (params: { [key: string]: any }) => Promise<void>;
  persistantFormValueKey?: string;
}

export default function FormListParams({
  form,
  onSubmit,
  persistantFormValueKey,
}: DashboardParamsProps) {
  const { isOnline } = useNetworkStatus();
  const { getPersistantFormValue, setPersistantFormValue } =
    usePersistantFormValues();

  const persistantFormValues = persistantFormValueKey
    ? getPersistantFormValue(persistantFormValueKey)
    : undefined;

  const [formValid, setFormValid] = useState<IPenFormValid[]>([]);
  const [formValues, setFormValues] = useState<IFormData | undefined>(
    persistantFormValues ?? undefined
  );
  const [isProcessingButtonVisible, setIsProcessingButtonVisible] =
    useState<Boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false);

  const hasSubmitted = useRef<Boolean>(false);
  // const prevFormName = useRef<string>("");

  const dataStatus = useMemo<Number | undefined>(
    () => getFormDataStatus(formValid),
    [formValid]
  );
  const isReadyForInitialSubmit = dataStatus !== undefined;

  // const formName = form?.FormName;

  const resetForm = useCallback(() => {
    setFormValues({} as IFormData);
    setFormValid([]);
  }, []);

  const submitForm = async () => {
    if (formValues === undefined || dataStatus !== DATA_STATUS.COMPLETE) {
      return;
    }

    // Before submit
    setIsProcessingButtonVisible(true);

    // Submit
    const params = reduceFormDataToObject(formValues);
    await onSubmit(params);

    // After submit
    setIsProcessingButtonVisible(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  /**
   * Trigger onSubmit only once on mount
   */
  useEffect(() => {
    if (!hasSubmitted.current && isReadyForInitialSubmit) {
      hasSubmitted.current = true;
      submitForm();
    }

    setLoaded(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReadyForInitialSubmit]);

  const handleParamsChange = (
    field: IFormField,
    penId: string,
    formValue: IFormValue
  ) => {
    if (!field?.Ref || !penId)
      return new Error("'ref' and 'pen' are required properties.");

    setFormValues((prevState) => {
      const dataSources: IFormValueDataSources = {
        this: prevState ?? {},
        // _custom: {
        //   farmId: farmId,
        //   houseId: houseId,
        // },
        _field: field,
      };

      try {
        const newState = buildFormValues(
          field,
          penId,
          formValue,
          form,
          dataSources,
          undefined,
          undefined,
          0,
          undefined
        );

        return newState;
      } catch (error) {
        console.error(error);
      }

      return prevState as any;
    });
  };

  const handleParamsValidate = (
    fieldId: string, // We use the id here because the field.Ref is not unique. (e.g. when using repeater fields `field.Ref_1`)
    field: IFormField,
    penNumber: string,
    valid: boolean,
    complete: boolean,
    required: boolean
  ) => {
    setFormValid((prevState) => {
      const newFormValid = prevState.filter(
        (fv) =>
          !(
            fv.Ref === fieldId &&
            (isNullEmptyOrWhitespace(field.QuestionGroup) ||
              fv.QuestionGroup === field.QuestionGroup) &&
            fv.Pen.toString() === penNumber.toString()
          )
      );
      newFormValid.push({
        Ref: fieldId,
        QuestionGroup: field.QuestionGroup,
        Pen: penNumber,
        Valid: valid,
        Complete: complete,
        Required: required,
      });

      return newFormValid;
    });
  };

  const handleSubmit = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();

    if (dataStatus !== DATA_STATUS.COMPLETE) {
      toast.error("Form is not complete. Please fill out all required fields");

      return;
    }

    if (persistantFormValueKey) {
      setPersistantFormValue(persistantFormValueKey, formValues);
    }

    submitForm();
  };

  const handleClearFilters = () => {
    resetForm();
  };

  if (!isOnline) {
    return (
      <Alert theme="warning" className="mb-6">
        You are offline. Please connect to the internet to enable filtered
        dashboards.
      </Alert>
    );
  }

  if (!loaded) {
    return <FieldsetSkeleton />;
  }

  return (
    <>
      <Card bodyClassName="space-y-4">
        <CardTitle>
          <div className="flex justify-between w-full">
            <div>Filters</div>
          </div>
        </CardTitle>
        <div className="grid grid-cols-1 tablet:grid-cols-2 desktop:grid-cols-3 gap-4 pt-4">
          {form ? (
            <>
              {form.FormFields.map((ff) => (
                <div>
                  <FormField
                    key={ff.Ref}
                    {...{
                      id: ff.Ref,
                      field: ff,
                      penNumber: "1",
                      setFieldValue: handleParamsChange,
                      setFieldValid: handleParamsValidate,
                      formValues: getPenDataFromFormData("1", formValues),
                    }}
                  />
                </div>
              ))}
            </>
          ) : null}
        </div>
        <div className="flex flex-col w-full tablet:inline-flex tablet:flex-row tablet:space-x-4 text-right justify-end justify-items-end">
          <Button
            className="whitespace-nowrap py-2 order-last tablet:order-first"
            theme="text"
            onClick={handleClearFilters}
          >
            Clear filters
          </Button>
          {isProcessingButtonVisible ? (
            <ButtonClicked />
          ) : (
            <Button theme="primary" onClick={handleSubmit}>
              Apply Filters
            </Button>
          )}
        </div>
      </Card>
    </>
  );
}
