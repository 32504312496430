import { useState, useEffect } from "react";
import { Transition } from "@headlessui/react";
import Breadcrumb from "components/Breadcrumb";
import { CHART_TYPE } from "constants.js";
import { Alert, Button } from "components/core";
import { useParams, useNavigate } from "react-router-dom";
import { v4 as uuid } from "uuid";
import { formatChartAttrs, createChartId } from "helpers/dashboardUtilities";
import { buildListPageUrl } from "helpers/redirectUtilities";
import AddChart from "components/Dashboard/Builder/AddChart";
import FieldSkeleton from "components/core/Forms/FieldSkeleton";
import SectionTitle from "components/SectionTitle";
import Dashboard from "components/Dashboard";
import { useActiveMenu } from "hooks/useActiveMenu";
import { DashboardForm } from "components/Dashboard/Builder/DashboardForm";
import { Pencil, Trash2} from "lucide-react";
import BreadcrumbItem from "components/core/Breadcrumbs/BreadcrumbItem";
import { BreadcrumbDivider } from "components/core/Breadcrumbs/BreadcrumbDivider";
import {
  IChart,
  IChartKey,
  IDashboard,
  POSITION_DIRECTION,
} from "components/Dashboard/types";
import { isNullEmptyOrWhitespace } from "helpers/stringUtilities";
import classNames from "classnames";
import {
  useDashboardGetOneById,
  useDashboardUpdateOrCreate,
  useDashboardDelete,
} from "hooks/useDashboard";
import { useFormGetMany } from "hooks/useForm";
import { toast } from "react-toastify";

const chartTypes = Object.entries(CHART_TYPE).map(([key, value]) => {
  return {
    id: key.toLowerCase(),
    title: value,
  };
});

const defaultDashboard: IDashboard = {
  id: uuid(),
  dataSources: "",
  charts: [],
  title: "",
  farmGroups: "",
  menuId: "",
  sortOrder: 0,
};

export default function Builder() {
  const { id: dashboardId, view } = useParams();
  const navigate = useNavigate();
  const { activeMenu } = useActiveMenu();
  const moduleFeatureGroup = activeMenu?.ModuleFeatureGroup;
  const module = activeMenu?.Module;

  const { data: forms } = useFormGetMany();
  const {
    dashboard,
  } = useDashboardGetOneById({
    enabled: !isNullEmptyOrWhitespace(dashboardId),
    id: dashboardId,
  });
  const {
    mutate: mutateDashboard,
  } = useDashboardUpdateOrCreate({
    onSuccess: () => {
      toast.success("Dashboard saved successfully.");

      return navigate(buildListPageUrl(activeMenu));
    },
    onError: () => {
      toast.error("An error occurred while saving your dashboard.");
    },
  });
  const {
    mutate: mutateDashboardDelete,
  } = useDashboardDelete({
    onSuccess: () => {
      toast.success("Dashboard deleted successfully.");

      return navigate(buildListPageUrl(activeMenu));
    },
    onError: () => {
      toast.error("An error occurred while deleting your dashboard.");
    },
  });

  const [unsavedDashboard, setUnsavedDashboard] =
    useState<IDashboard>(defaultDashboard);
  const [openDashboardForm, setOpenDashboardForm] = useState(
    view?.toLowerCase() === "new" ? true : false
  );
  const [isEditingChart, setIsEditingChart] = useState(false);

  const [error, setError] = useState<string>("");

  useEffect(() => {
    setUnsavedDashboard(dashboard ?? defaultDashboard);
  }, [dashboard]);

  useEffect(() => {
    if (view?.toLowerCase() === "new") {
      setOpenDashboardForm(true);
    }
  }, [view]);

  const filteredForms =
    forms.filter(
      (f) =>
        f.FormType?.toLowerCase() === moduleFeatureGroup?.toLowerCase() &&
        f.ModuleName?.toLowerCase() === module?.toLowerCase()
    ) ?? [];

  const addChart = (newChart: IChart) => {
    if (newChart.type === undefined) {
      // Avoid adding empty charts
      return;
    }

    if (unsavedDashboard === undefined) {
      setError("Dashboard not found.");

      return;
    }

    if (unsavedDashboard?.charts?.findIndex((c) => c.id === newChart.id) === -1) {
      const newDashboard: IDashboard = {
        ...unsavedDashboard,
        charts: [...unsavedDashboard?.charts, newChart],
      };

      // Sort by position, desc
      newDashboard.charts.sort((a, b) => a.position - b.position);

      setUnsavedDashboard(newDashboard);
    } else {
      const newDashboard: IDashboard = {
        ...unsavedDashboard,
        charts: unsavedDashboard?.charts?.map((c) =>
          c.id === newChart.id ? newChart : c
        ) ?? [newChart],
      };

      // Sort by position, desc
      newDashboard.charts.sort((a, b) => a.position - b.position);

      setUnsavedDashboard(newDashboard);
    }
  };

  const onClickSave = async () => {
    const errors = validateForm();
    setError(errors);
    if (errors) {
      return;
    }

    mutateDashboard(unsavedDashboard);
  };

  const onClickDeleteDashboard = async () => {
    if (isNullEmptyOrWhitespace(dashboardId)) {
      return;
    }

    await mutateDashboardDelete(dashboardId!);

    return navigate(buildListPageUrl(activeMenu));
  };

  const onClickCancel = () => {
    return navigate(buildListPageUrl(activeMenu));
  };

  const onDeleteChart = async (chartId: string) => {
    console.log("onDeleteChart", chartId);
    try {
      const newDashboard: IDashboard = {
        ...unsavedDashboard,
        charts: unsavedDashboard.charts.filter((c) => c.id !== chartId),
      };

      setUnsavedDashboard(newDashboard);

      toast.success("Chart deleted successfully.");
    } catch (err) {
      console.error(err);
    }
  };

  const onChangeChartTitle = (chartId: string, value: string) => {
    const chartIndex = unsavedDashboard.charts.findIndex(
      (x) => x.id === chartId
    );
    const chart = unsavedDashboard.charts[chartIndex];

    const newChart = {
      ...chart,
      title: value,
    };

    const newDashboard: IDashboard = {
      ...unsavedDashboard,
      charts: [...unsavedDashboard.charts],
    };

    // Insert new chart in same array location
    newDashboard.charts.splice(chartIndex, 1, newChart);

    setUnsavedDashboard(newDashboard);
  };

  const onChangeChartAttr = (chartId: string, name: string, value: string) => {
    const chartIndex = unsavedDashboard.charts.findIndex(
      (x) => x.id === chartId
    );
    const chart = unsavedDashboard.charts[chartIndex];

    const newChart = {
      ...chart,
      attrs: {
        ...chart.attrs,
        [name]: value,
      },
    };

    const newDashboard: IDashboard = {
      ...unsavedDashboard,
      charts: [...unsavedDashboard.charts],
    };

    // Insert new chart in same array location
    newDashboard.charts.splice(chartIndex, 1, newChart);

    setUnsavedDashboard(unsavedDashboard);
  };

  const onChangeChartSetting = (chartId: string, name: string, value: any) => {
    const chartIndex = unsavedDashboard.charts.findIndex(
      (x) => x.id === chartId
    );
    const chart = unsavedDashboard.charts[chartIndex];

    const newChart = {
      ...chart,
      settings: {
        ...chart.settings,
        [name]: value,
      },
    };

    const newDashboard: IDashboard = {
      ...unsavedDashboard,
      charts: [...unsavedDashboard.charts],
    };

    // Insert new chart in same array location
    newDashboard.charts.splice(chartIndex, 1, newChart);

    setUnsavedDashboard(newDashboard);
  };

  const onChangeTitle = (value: string) => {
    const newDashboard: IDashboard = {
      ...unsavedDashboard,
      title: value,
    };

    setUnsavedDashboard(newDashboard);
  };

  const onChangeFarmGroups = (values: string) => {
    const newDashboard: IDashboard = {
      ...unsavedDashboard,
      farmGroups: values,
    };

    setUnsavedDashboard(newDashboard);
  };

  const onChangeMenu = (values: string) => {
    const newDashboard: IDashboard = {
      ...unsavedDashboard,
      menuId: values,
    };

    setUnsavedDashboard(newDashboard);
  };

  const onChangeDataSources = (value: string) => {
    const newDashboard: IDashboard = {
      ...unsavedDashboard,
      dataSources: value,
    };

    setUnsavedDashboard(newDashboard);
  };

  const onChangeSortOrder = (value: number) => {
    const newDashboard = {
      ...unsavedDashboard,
      sortOrder: value,
    };

    setUnsavedDashboard(newDashboard);
  };

  const handleAddChart = (chartType: IChart["type"]) => {
    if (!chartType) throw new Error("Chart type is required");

    // Setup chart defaults
    const newChart: IChart = {
      id: uuid(),
      title: "New Chart",
      showHeader: true,
      settings: {
        showTitle: true,
        showConfig: true,
        colors: ["#df386f", "#01295F", "#437F97", "#849324", "#FFB30F"],
      },
      keys: [],
      tooltipKeys: [],
      standardKeys: [],
      type: chartType,
      attrs: {},
      position: unsavedDashboard?.charts?.length ?? 0,
    };

    addChart(newChart);
  };

  const handleAddMetrics = (chartId: string, chartKeys: IChartKey[]) => {
    if (!chartKeys) {
      return;
    }

    const chartIndex = unsavedDashboard.charts.findIndex(
      (x) => x.id === chartId
    );
    const chart = unsavedDashboard.charts[chartIndex];

    const newChart: IChart = {
      ...chart,
      keys: [], // Clear existing keys
    };

    for (const chartKey of chartKeys) {
      // prevent duplicate metric ids
      const newChartId = createChartId(newChart.keys, chartKey);

      // Add metric to the list
      newChart.keys.push({
        ...chartKey,
        id: newChartId,
      });
    }

    // Set widget title if not set
    if (!newChart.title) {
      newChart.title = `${newChart.keys[0].title} Chart`;
    }

    // Update chart attrs
    const _chartAttrs = formatChartAttrs(newChart);
    newChart.attrs = _chartAttrs;

    const newDashboard: IDashboard = {
      ...unsavedDashboard,
      charts: [...unsavedDashboard.charts],
    };

    // Insert new chart in same array location
    newDashboard.charts.splice(chartIndex, 1, newChart);

    setUnsavedDashboard(newDashboard);
  };

  const handleAddTooltipMetrics = (
    chartId: string,
    tooltipKeys: IChartKey[]
  ) => {
    if (!tooltipKeys) {
      return;
    }

    const chartIndex = unsavedDashboard.charts.findIndex(
      (x) => x.id === chartId
    );
    const chart = unsavedDashboard.charts[chartIndex];

    const newChart: IChart = {
      ...chart,
      tooltipKeys: [], // Clear existing keys
    };

    for (const tooltipKey of tooltipKeys) {
      // prevent duplicate metric ids
      const newChartId = createChartId(newChart.tooltipKeys, tooltipKey);

      // Add metric to the list
      newChart.tooltipKeys.push({
        ...tooltipKey,
        id: newChartId,
      });
    }

    const newDashboard: IDashboard = {
      ...unsavedDashboard,
      charts: [...unsavedDashboard.charts],
    };

    // Insert new chart in same array location
    newDashboard.charts.splice(chartIndex, 1, newChart);

    setUnsavedDashboard(newDashboard);
  };

  const handleAddStandardMetrics = (
    chartId: string,
    standardKeys: IChartKey[]
  ) => {
    if (!standardKeys) {
      return;
    }

    const chartIndex = unsavedDashboard.charts.findIndex(
      (x) => x.id === chartId
    );
    const chart = unsavedDashboard.charts[chartIndex];

    const newChart: IChart = {
      ...chart,
      standardKeys: [], // Clear existing keys
    };

    for (const standardKey of standardKeys) {
      // Add metric to the list
      newChart.standardKeys.push(standardKey);

      // prevent duplicate metric ids
      const newChartId = createChartId(newChart.standardKeys, standardKey);

      // Add metric to the list
      newChart.standardKeys.push({
        ...standardKey,
        id: newChartId,
      });
    }

    const newDashboard: IDashboard = {
      ...unsavedDashboard,
      charts: [...unsavedDashboard.charts],
    };

    // Insert new chart in same array location
    newDashboard.charts.splice(chartIndex, 1, newChart);

    setUnsavedDashboard(newDashboard);
  };

  function handleChangePosition(
    chartId: string,
    direction: POSITION_DIRECTION
  ) {
    const chartIndex = unsavedDashboard.charts.findIndex(
      (x) => x.id === chartId
    );

    if (
      (direction === POSITION_DIRECTION.UP && chartIndex <= 0) ||
      (direction === POSITION_DIRECTION.DOWN &&
        chartIndex >= unsavedDashboard.charts.length - 1)
    ) {
      return;
    }

    const chart = { ...unsavedDashboard.charts[chartIndex] };

    const newDashboard: IDashboard = {
      ...unsavedDashboard,
      charts: unsavedDashboard.charts.filter((x) => x.id !== chartId),
    };

    // Insert new chart in new array location
    if (direction === POSITION_DIRECTION.UP) {
      newDashboard.charts.splice(chartIndex - 1, 0, chart);
    } else if (direction === POSITION_DIRECTION.DOWN) {
      newDashboard.charts.splice(chartIndex + 1, 0, chart);
    }

    // Update position of all charts
    for (let i = 0; i < newDashboard.charts.length; i++) {
      newDashboard.charts[i].position = i;
    }

    setUnsavedDashboard(newDashboard);
  }

  function onEditingChart(value: boolean) {
    setIsEditingChart(value);
  }

  function validateForm() {
    if (isNullEmptyOrWhitespace(unsavedDashboard.id)) {
      return "Dashboard ID is required.";
    }

    if (isNullEmptyOrWhitespace(unsavedDashboard.title)) {
      return "Dashboard title is required.";
    }

    if (isNullEmptyOrWhitespace(unsavedDashboard.dataSources)) {
      return "Data source is required.";
    }

    return "";
  }

  //#endregion

  return (
    <main className="flex-grow overflow-x-hidden">
      <div className="relative z-20 bg-white border-b border-gray-100">
        <Breadcrumb
          key="breadcrumb"
          showHome={false}
          showFarm={false}
          showHouse={false}
          showDivider={true}
        >
          <BreadcrumbDivider />
          <BreadcrumbItem
            // icon={
            //   <span className="text-gray-500">
            //     <HomeIcon className="shrink-0 h-5 w-5" />
            //     <span className="sr-only">Home</span>
            //   </span>
            // }
            title={
              dashboard?.title
                ? `Editing ${dashboard.title}`
                : `Add New Dashboard`
            }
            subtitle="Dashboard Builder"
            showDivider={false}
            onClick={() => {
              navigate(buildListPageUrl(activeMenu));
            }}
          />
        </Breadcrumb>
      </div>
      <div className="grid grid-cols-2 gap-4 p-4">
        <div className="col-span-full">
          <SectionTitle>
            {dashboard?.title
              ? `Editing ${dashboard.title}`
              : `Dashboard Builder`}

            <div className="inline-flex space-x-4">
              <div
                className={classNames(
                  openDashboardForm ? "text-primary" : "text-gray-300",
                  "hover:text-primary cursor-pointer inline-flex items-center space-x-1 text-xs group transition duration-500 ease-in-out"
                )}
                onClick={() => {
                  setOpenDashboardForm((prev) => !prev);
                }}
                title="Open dashboard form"
              >
                <Pencil className="w-4 h-4" />
                {openDashboardForm ? <span>Cancel</span> : null}
              </div>

              <div
                className="text-gray-300 hover:text-primary cursor-pointer inline-flex items-center space-x-1 text-xs group transition duration-500 ease-in-out"
                onClick={() => {
                  if (
                    window.confirm(
                      "Are you sure you wish to delete the dashboard?"
                    )
                  )
                    onClickDeleteDashboard();
                }}
                title="Edit chart keys"
              >
                <Trash2 className="w-4 h-4" />
              </div>
            </div>
          </SectionTitle>
          {error && <Alert theme="danger">{error}</Alert>}
        </div>

        <Transition
          show={openDashboardForm}
          className="col-span-full"
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-y-4 tablet:translate-y-0 tablet:scale-95"
          enterTo="opacity-100 translate-y-0 tablet:scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0 tablet:scale-100"
          leaveTo="opacity-0 translate-y-4 tablet:translate-y-0 tablet:scale-95"
        >
          <DashboardForm
            dashboard={unsavedDashboard}
            forms={filteredForms}
            onChangeTitle={onChangeTitle}
            onChangeFarmGroups={onChangeFarmGroups}
            onChangeMenu={onChangeMenu}
            onChangeDataSource={onChangeDataSources}
            onChangeSortOrder={onChangeSortOrder}
          />
        </Transition>

        <Transition
          show={unsavedDashboard !== undefined}
          className="col-span-full"
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-y-4 tablet:translate-y-0 tablet:scale-95"
          enterTo="opacity-100 translate-y-0 tablet:scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0 tablet:scale-100"
          leaveTo="opacity-0 translate-y-4 tablet:translate-y-0 tablet:scale-95"
        >
          <div className="col-span-full">
            {chartTypes.length > 0 ? (
              <AddChart
                chartTypes={chartTypes}
                className="col-span-12 mb-6 h-12"
                onAddChart={handleAddChart}
              />
            ) : (
              <FieldSkeleton />
            )}
          </div>
          <div className="col-span-full">
            <Dashboard
              dashboard={unsavedDashboard}
              isEditable={true}
              onEditing={onEditingChart}
              onAddMetrics={handleAddMetrics}
              onAddTooltipMetrics={handleAddTooltipMetrics}
              onAddStandardMetrics={handleAddStandardMetrics}
              onDeleteChart={onDeleteChart}
              onChangeSetting={onChangeChartSetting}
              onChangeChartAttr={onChangeChartAttr}
              onChangeChartPosition={handleChangePosition}
              onChangeChartTitle={onChangeChartTitle}
            />
          </div>
        </Transition>

        <Transition
          show={!isEditingChart}
          className="col-span-full"
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-y-4 tablet:translate-y-0 tablet:scale-95"
          enterTo="opacity-100 translate-y-0 tablet:scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0 tablet:scale-100"
          leaveTo="opacity-0 translate-y-4 tablet:translate-y-0 tablet:scale-95"
        >
          <div className="flex space-x-2 justify-between order-2">
            <Button className="mt-4" onClick={onClickCancel}>
              Cancel
            </Button>
            <Button theme="primary" className="mt-4" onClick={onClickSave}>
              Save
            </Button>
          </div>
        </Transition>
      </div>
    </main>
  );
}
