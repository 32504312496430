import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import ReactGA from "react-ga";
import Dexie from "dexie";
import React from "react";

if (process.env.NODE_ENV === "test") {
  // Start the mocking conditionally.
  const { worker } = require("./mocks/browser");
  worker.start();
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

/**
 * @see https://dexie.org/docs/Dexie/Dexie.on.storagemutated#supporting-safari-153-and-below
 */
if (typeof BroadcastChannel === 'undefined') {
  navigator.serviceWorker.onmessage = (event) => {
    if (event.data.type === 'storagemutated') {
      Dexie.on('storagemutated').fire(event.data.updatedParts);
    }
  };
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    const event = new CustomEvent("serviceWorkerUpdateAvailable", {
      detail: registration,
    });
    document.dispatchEvent(event);
  },
  onInstalling: (registration) => {
    const event = new CustomEvent("serviceWorkerInstalling", {
      detail: registration,
    });
    document.dispatchEvent(event);
  }
}); //offline first

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
function sendToAnalytics({ id, name, value }) {
  ReactGA.event({
    category: "Web Vitals",
    action: name,
    // Google Analytics metrics must be integers, so the value is rounded.
    // For CLS the value is first multiplied by 1000 for greater precision
    // (note: increase the multiplier for greater precision if needed).
    value: Math.round(name === "CLS" ? value * 1000 : value), // values must be integers
    // The `id` value will be unique to the current page load. When sending
    // multiple values from the same page (e.g. for CLS), Google Analytics can
    // compute a total by grouping on this ID (note: requires `eventLabel` to
    // be a dimension in your report).
    label: id, // id unique to current page load
    nonInteraction: true,
    // Use `sendBeacon()` if the browser supports it.
    transport: "beacon",
  });
}
reportWebVitals(sendToAnalytics);
