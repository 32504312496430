import { type IUser } from "helpers/formUtilities";
import {
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import useFetch from "hooks/useFetch";
import { UserContext } from "context/UserProvider";
import { deleteCookie, setCookie } from "helpers/storageUtilities";



export const useUser = () => {
  const context = useContext(UserContext);

  if (!context) {
    throw new Error("useUser must be used within a AppDataProvider");
  }

  return context;
};

type useUserGetMeProps = {
  enabled?: boolean;
  onSuccess?: (response: Response, responseBody: any) => void;
  onError?: (errMessage: string) => void;
};

export const useUserGetMe = ({ 
  enabled = true,
  onSuccess,
  onError,
}: useUserGetMeProps = {}) => {
  const { isLoading, error, execute } = useFetch({
    onSuccess: (response, responseBody) => {
      if (responseBody?.isAuthenticated) {
        setCookie("signedIn", "true", { expires: 30 }); // 30 days

        return onSuccess?.(response, responseBody);
      }

      return onError?.(responseBody?.message);
    },
    onError,
  });

  const [data, setData] = useState<IUser | undefined>(undefined);

  const fetchData = useCallback(async () => {
    const { data } = await execute("GET", "/api/user-get");

    const user = data?.user ? {
      username: data?.user?.UserName,
      fullname: data?.user?.FullName,
      email: data?.user?.Email,
      permissionLevelId: data?.user?.PermissionLevel,
    } as IUser : undefined;

    setData(user);

    return user;
  }, [execute]);

  useEffect(() => {
    if (enabled) {
      fetchData();
    }
  }, [enabled, fetchData]);

  return { isLoading, error, data };
};

export const isSuperUser = (user: IUser | undefined) => {
  return (
    user?.permissionLevelId?.toString() === "0"
  );
};

type useUserSignInProps = {
  onSuccess?: (response: Response, responseBody: any) => void;
  onError?: (errMessage: string) => void;
};

export const useUserSignIn = ({
  onSuccess,
  onError,
}: useUserSignInProps = {}) => {
  const { isLoading, error, execute } = useFetch({
    onSuccess: (response, responseBody) => {
      if (responseBody?.isAuthenticated) {
        setCookie("signedIn", "true", { expires: 30 }); // 30 days

        return onSuccess?.(response, responseBody);
      }

      return onError?.(responseBody?.message);
    },
    onError,
  });

  const mutate = useCallback(
    async (credentials: {
      username: string,
      password: string,
    }) => {
      const { data } = await execute("POST", "/api/signin", { ...credentials });

      return data?.d;
    },
    [execute]
  );

  return { isLoading, error, mutate };
};

type useUserSignOutProps = {
  onSuccess?: (response: Response, responseBody: any) => void;
  onError?: (errMessage: string) => void;
};

export const useUserSignOut = ({
  onSuccess,
  onError,
}: useUserSignOutProps = {}) => {
  const { execute } = useFetch({
    onSuccess: (response, responseBody) => {
      if (responseBody?.status === 200) {
        setUser(undefined);
        deleteCookie("signedIn");

        return onSuccess?.(response, responseBody);
      }

      return onError?.(responseBody?.message);
    },
    onError,
  });
  const { setUser } = useUser();

  const mutate = useCallback(async () => {
    const { data } = await execute("POST", "/api/signout");

    return data;
  }, [execute]);

  return { mutate };
};